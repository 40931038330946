import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920.000000 1920.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1920.000000) scale(0.100000,-0.100000)">
          
          <path d="M9300 14443 c-157 -91 -519 -301 -805 -466 -286 -165 -576 -333 -645
-372 -114 -67 -1355 -783 -2223 -1283 l-357 -206 2 -2505 3 -2504 310 -179
c171 -99 373 -216 450 -260 77 -44 356 -206 620 -358 264 -153 716 -414 1005
-580 289 -167 561 -324 605 -350 44 -25 235 -135 425 -245 190 -109 424 -244
520 -300 96 -56 224 -130 283 -164 l108 -62 277 159 c152 88 491 284 752 434
588 340 1987 1148 2211 1277 92 53 374 215 627 361 l461 265 1 2506 0 2506
-577 334 c-318 183 -1010 582 -1538 887 -528 305 -1066 615 -1195 690 -129 75
-345 199 -480 277 -135 78 -309 179 -386 223 -78 45 -148 82 -155 81 -8 0
-142 -75 -299 -166z m326 -112 c5 -5 302 -177 659 -384 358 -206 1026 -592
1485 -857 459 -265 1081 -624 1383 -798 l547 -316 -2 -2365 -3 -2365 -115 -66
c-63 -37 -788 -455 -1610 -930 -822 -475 -1578 -911 -1680 -970 -102 -59 -296
-170 -431 -248 -135 -78 -251 -142 -258 -142 -7 0 -189 102 -404 226 -216 125
-448 259 -517 299 -69 40 -343 198 -610 352 -267 154 -719 415 -1005 580 -286
165 -565 326 -620 358 -163 95 -393 227 -673 388 l-262 151 2 2369 3 2370 190
109 c105 61 278 161 387 224 108 62 384 222 615 355 230 132 668 385 973 562
305 176 638 368 740 427 102 59 318 184 480 277 162 94 385 223 495 286 110
64 205 116 211 117 5 0 14 -4 20 -9z"/>
<path d="M9584 14017 c-18 -10 -491 -282 -1214 -700 -190 -109 -464 -268 -610
-352 -343 -198 -980 -566 -1235 -712 -110 -64 -258 -150 -330 -191 -71 -42
-147 -85 -168 -98 -22 -12 -35 -24 -30 -27 4 -2 357 -206 783 -452 426 -246
980 -565 1230 -710 1583 -914 1584 -915 1593 -915 3 0 380 217 839 481 890
515 1583 915 1843 1064 594 343 911 527 917 533 6 6 -687 410 -2167 1263 -148
85 -448 258 -665 384 -806 466 -765 443 -786 432z m-354 -1872 l0 -955 595 0
595 0 -2 -202 -3 -203 -815 0 -815 0 -3 1158 -2 1157 225 0 225 0 0 -955z"/>
<path d="M5780 9486 l0 -2084 183 -105 c100 -58 252 -145 337 -194 85 -50 508
-294 940 -543 432 -250 900 -520 1040 -601 140 -80 446 -257 680 -392 l425
-246 3 2082 c2 1657 -1 2084 -10 2092 -7 7 -328 193 -713 415 -385 222 -1046
603 -1470 848 -972 561 -1002 578 -1223 706 -100 58 -185 106 -187 106 -3 0
-5 -938 -5 -2084z m1272 -362 l3 -486 420 468 c231 257 426 475 433 486 12 17
32 18 288 18 212 0 274 -3 274 -12 0 -7 -224 -255 -498 -550 -274 -295 -504
-543 -511 -552 -10 -12 40 -70 320 -363 360 -377 493 -516 667 -695 61 -64
112 -123 112 -132 0 -14 -31 -16 -274 -16 l-274 0 -479 506 -478 507 -3 -507
-2 -506 -220 0 -220 0 0 1160 0 1160 220 0 220 0 2 -486z"/>
<path d="M12495 11039 c-506 -292 -1035 -598 -1175 -678 -435 -251 -710 -410
-1115 -644 l-390 -225 -3 -2086 c-1 -1147 -1 -2086 1 -2086 2 0 156 88 343
196 186 108 458 265 604 349 146 84 479 276 740 427 261 151 786 454 1165 672
380 219 707 409 728 422 l37 24 0 2080 c0 1144 -4 2080 -8 2080 -4 0 -421
-239 -927 -531z m-854 -1439 c354 -36 640 -197 813 -458 134 -204 198 -430
198 -702 1 -335 -107 -617 -318 -832 -145 -148 -312 -238 -549 -294 -60 -15
-151 -18 -637 -21 l-568 -4 0 1154 c0 634 3 1157 7 1160 11 11 942 8 1054 -3z"/>
<path d="M11027 9184 c-4 -4 -7 -335 -7 -736 l0 -729 327 3 c309 4 332 5 398
27 172 56 307 169 383 321 66 132 77 188 77 380 0 145 -3 174 -23 240 -92 291
-289 455 -590 490 -96 11 -555 14 -565 4z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
